.login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.login-form {
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
}

.login-form__title {
    text-align: center;
    margin-bottom: 20px;
}

.login-form__group {
    margin-bottom: 15px;
}

.login-form__label {
    font-weight: bold;
}

.login-form__input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-form__error {
    color: red;
    margin-bottom: 10px;
}

.login-form__button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
