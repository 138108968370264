.login-button {
    color: white;
    /* border-color: #F0FF42 !important; */
    background-color: #32ff69b8 !important;
    margin-left: 10px;
    padding: 10px 20px;
    font-size: 1.5rem;
    text-transform: none;
}

.login-button:hover {
    color: white;
    background-color: #0d9d49 !important;
}
