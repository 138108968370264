.new-notification-count {
  position: absolute;
  top: -5px;
  right: -6px;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .dropdown-toggle::after {
  display: none;
  /* remove the dropdown arrow on the bell icon */
}

.notification-bell-icon {
  color: white;
  font-size: 25px;
}

.notification-container {
  margin-right: 25px;
}

@media (max-width: 768px) {
  .notification-container.show .dropdown-menu {
    position: fixed;
    width: 100%;
    left: 0;
    top: 8vh;
    overflow-y: auto;
  }
}

@media (min-width: 769px) {
  .notification-container .dropdown-menu {
    width: 45vw;
  }
}

.notification-item-container {
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-width: 100%;
}

.dropdown-menu {
  background-color: white !important;
}

.notification-item-container, .notification-item-read, .notification-item-unread {
  white-space: normal !important; /* Force wrapping */
  overflow-wrap: break-word !important; /* Break words to prevent horizontal overflow */
  word-wrap: break-word !important; /* For older browsers */
  max-width: 100%; /* Ensure content is constrained by its container's width */
  overflow-x: visible !important; /* Make sure content doesn't get hidden */
}


.notification-item-read {
  background-color: white !important; /* color for read notifications */
  color: #343a40 !important; /* Darker text color for better readability */
}

.notification-item-unread {
  background-color: #00b26e !important; /* Darker blue background */
  color: white !important; /* White text for high contrast */
  border-left: 4px solid #0056b3; /* Brighter blue border for emphasis */
}
