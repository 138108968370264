.sidebar {
    margin-top: 3vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100%;
    background-color: #fff;
    padding: 60px 0;
    transition: transform 0.3s ease-in-out;
    z-index: 90;
    border-right: solid 1px #d3d3d3; /* Light gray */
}

.sidebar-margin {
  margin-left: 14%;
} 

.sidebar.hide {
    transform: translateX(-100%);
}

/* Media query to hide sidebar on smaller screens */
/* @media (max-width: 992px) {
    .sidebar {
        display: none;
    }
} */

.hide {
    display: none;
}

@media (max-width: 992px) {
    .sidebar.hide {
        display: none;
    }
}

.sidebar-link {
    text-decoration: none;
    color: inherit;
}

.sidebar-link.active {
    background-color: #32ff69b8;
}

.sidebar-link:hover {
    /* color: #32ff69b8; */
    background-color: #32ff69b8 !important;
}

.sidebar-icon {
    min-width: 40px;
}