.account-button {
  color: white;
  background-color: #000000 !important;
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 1.5rem;
  text-transform: none;
}

.account-button:hover {
  color: white;
  background-color: rgba(0, 37, 10, 0.72) !important;
}
