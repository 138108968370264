@import url('https://fonts.googleapis.com/css?family=Didact+Gothic|Bungee+Inline');

body,
html {
  margin: 0;
}

/* Keep this in mind, could be good somewhere else */
/* .container {
  height: 100vh;
  width: 100%;
  background: -moz-linear-gradient(top, #febbbb 0%, #fe9090 45%, #ff5c5c 100%);
  background: -webkit-linear-gradient(top, #febbbb 0%, #fe9090 45%, #ff5c5c 100%);
  background: linear-gradient(to bottom, #febbbb 0%, #fe9090 45%, #ff5c5c 100%);
} */


/** styling for the hamburger menu **/

.toggle-button {
  position: fixed;
  top: 10px;
  /*horizontal centering: https://css-tricks.com/forums/topic/horizontal-centering-of-an-absolute-element/ */
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 9px 10px;
  margin: 8px 15px 8px 0;
  height: 20px;
  width: 35px;
  cursor: pointer;
  z-index: 3;
}

.bar {
  background-color: white;
  display: block;
  width: 100%;
  height: 5px;
  border: 0;
  -webkit-transition: all .35s ease;
  transition: all .35s ease;
}

.bar+.bar {
  margin-top: 4px;
}

.middle {
  top: 15px;
}

.bottom {
  top: 30px;
}

.toggle-button:hover {
  opacity: .7;
}

.toggle-active .bar {
  background-color: #fff;
}

.toggle-active .top {
  -webkit-transform: translateY(6px) translateX(0) rotate(45deg);
}

.toggle-active .middle {
  opacity: 0;
}

.toggle-active .bottom {
  -webkit-transform: translateY(-12px) translateX(0) rotate(-45deg);
}

/** styling for the overlay. The sliding-down effect of the overlay is given by the initial zero height and the onclick 100% height. **/

.overlay {
  position: fixed;
  background-color: var(--color-secondary);
  top: 7.2%;
  /** TODO This needs to match the topbar no matter what**/
  left: 0;
  width: 100%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity .35s, visibility .35s, height .35s;
  z-index: 9000;
}

/* class added to the overlay on click*/

.nav-active {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.overlay ul {
  display: block;
  position: relative;
  top: 60px;
  left: 0;
  font-family: 'Didact Gothic', serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: left;
  list-style: none;
  padding: 0;
  padding-left: 20px;
}

.overlay ul li {
  display: block;
}

.overlay ul li a {
  display: block;
  /* Add this to make the anchor tag a block element */
  color: #fff;
  text-decoration: none;
  padding-left: 3rem;
  /* Add padding-left */
  text-indent: -2rem;
  /* Use a negative text-indent to counteract padding-left */
}

h1 {
  position: absolute;
  top: 40%;
  left: 50%;
  /*horizontal centering*/
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 2em;
  color: white;
  font-family: "Bungee Inline", sans-serif;

}

.hidden {
  opacity: 0;
}

.menu-item-text {
  display: inline-block;
  vertical-align: top;
  padding-left: 3rem;
}

.menu-item-icon {
  display: inline-block;
  vertical-align: top;
  width: 1.5rem;
  text-align: center;
  /* Center the icons within the fixed-width container */
}

.menu-item-text-logout {
  display: inline-block;
  vertical-align: top;
  padding-left: 3rem;
  color: red;
}

.menu-item-icon-logout {
  display: inline-block;
  vertical-align: top;
  width: 1.5rem;
  text-align: center;
  color: red;
}

/* Responsive font and icon sizes */
@media screen and (max-width: 990px) {
  .overlay ul {
    font-size: 2rem;
  }
  .menu-item-icon {
    width: 2rem;
  }
}

/* Responsive font and icon sizes */
@media screen and (max-width: 768px) {
  .overlay ul {
    font-size: 2rem;
  }
  .menu-item-icon {
    width: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .overlay ul {
    font-size: 1.5rem;
  }
  .menu-item-icon {
    width: 1.5rem;
  }
}

/* Burger Menu In Top Bar */
.burger-menu {
  display: inline-block;
  cursor: pointer;
  z-index: 9999;
}

.bar {
  width: 2rem;
  /* 35px */
  height: 0.3rem;
  /* 5px */
  background-color: #fff;
  margin: 0.375rem 0;
  /* 6px */
  transition: 0.3s;
}

.cross .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-0.5625rem, 0.375rem);
  /* (-9px, 6px) */
}

.cross .bar:nth-child(2) {
  opacity: 0;
}

.cross .bar:nth-child(3) {
  transform: rotate(45deg) translate(-0.5625rem, -0.375rem);
  /* (-9px, -6px) */
}