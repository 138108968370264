.topbar {
  position: sticky;
  top: 0;
  z-index: 9990;
  box-shadow: 0 2px 4px #171511;
  height: 7.5%;
  background-color: transparent;
}

@media (max-width: 600px) {
  .topbar {
    height: 9%;
  }
}

.topbar::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #b1ff01;
  z-index: -1;
}


.topbar-brand {
  color: #fff;
  font-weight: bold;
  margin-left: 5%;
}

.topbar-link-login {
  color: #fff;
  font-weight: bold;
}

.topbar-icon {
  margin-right: 5px;
}

@media (max-width: 767px) {
  .topbar-link {
    margin-right: 0;
    margin-bottom: 10px;
    display: block;
    text-align: center;
  }
}

.login-button {
  color: white;
  background-color: #32ff69 !important;
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 1.5rem;
  text-transform: none;
}

.login-button:hover {
  color: white;
  background-color: #32ff69b8 !important;
}

.burger-and-bell-container {
  display: flex;
  align-items: center;
}

.custom-badge {
  background-color: #6f42c1 !important;
  color: white !important;
}